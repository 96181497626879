export type ContactFormProperties = {
  [key: string]: ContactFormRules;
};

export type EmailFormProperties = {
  [key: string]: EmailFormRules;
};

export type ContactFormRules = {
  requiredLabel?: string;
  minLength?: number;
  maxLength?: number;
  errorMessage?: string;
  reg?: string;
};

export type EmailFormRules = {
  requiredLabel?: string;
  errorMessage?: string;
  reg?: string;
  invalidEmailMessage?: string;
};

export const CONTACT_FORM_PROPERTIES: ContactFormProperties = {
  firstname: {
    minLength: 2,
    maxLength: 40,
    reg: '.*[a-zA-Z].*[a-zA-Z].*',
    requiredLabel: 'This field is required',
    errorMessage: 'First name is required at least 2 alphabets.',
  },
  lastname: {
    minLength: 2,
    maxLength: 50,
    reg: '.*[a-zA-Z].*[a-zA-Z].*',
    requiredLabel: 'This field is required',
    errorMessage: 'Last name is required at least 2 alphabets.',
  },
  phone: {
    minLength: 6,
    maxLength: 16,
    reg: '^\\+[\\d]{5,15}$',
    requiredLabel: 'This field is required',
    errorMessage: 'Phone number is required at least 5 digits.',
  },
  email: {
    requiredLabel: 'This field is required',
    errorMessage: 'Email name is required.',
  },
};

export const EMAIL_FORM_PROPERTIES: EmailFormProperties = {
  newEmail: {
    reg: '[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}',
    requiredLabel: 'This field is required',
    errorMessage: 'Enter your new email',
    invalidEmailMessage: 'Email is invalid',
  },
  confirmEmail: {
    reg: '[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}',
    requiredLabel: 'This field is required',
    errorMessage: 'Confirm your new email',
    invalidEmailMessage: 'Email is invalid',
  },
};
